@import '../custom';

.cssip-list-item {
  &.border {
    border-width: 2px !important;
  }

  &--clickable {
    transition: background-color .25s;

    &:hover {
      background-color: scale-color($primary, $lightness: 90%) !important;
      text-decoration: none;
    }
  }
}
