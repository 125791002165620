@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
$font-family-sans-serif: 'Roboto', sans-serif;
$headings-font-family: 'Oswald', sans-serif;
$headings-font-weight: 400;
$primary: #40798c; // This colour is also used in index.html and manifest.json.
$secondary: #ff5050;
$tertiary: #32a852;
$dark: #4c4c4c;
$white: #fff;
$input-btn-focus-width: 0;
$enable-rounded: false;
$navbar-dark-color: $white;
$navbar-dark-hover-color: lighten($secondary, 20);
$dropdown-link-hover-bg: lighten($secondary, 20);
$zindex-dropdown: 1021;
$enable-negative-margins: true;
$navbar-height: 48px;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
