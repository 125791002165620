@import '../custom';

.cssip-navbar {
  &__container {
    @include media-breakpoint-up(md) {
      padding: 0 !important;
    }
  }

  &__brand {
    @include media-breakpoint-up(md) {
      margin-end: 1rem !important;
    }
  }
}

.nav-link {
  transition: color .25s;

  @include media-breakpoint-up(md) {
    background-color: rgba(255, 255, 255, .11);
    margin: 0 5px;
  }
}

.navbar-nav {
  // sass-lint:disable single-line-per-selector
  & > .nav-item > .nav-link, & > .nav-link {
    &.active {
      position: relative;

      &::before {
        @include media-breakpoint-up(md) {
          border-bottom: 2px solid lighten($secondary, 20);
          border-left: 0;
          height: 0;
          width: 100%;
        }

        border-right: 2px solid lighten($secondary, 20);
        bottom: 0;
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
      }
    }
  }
}
