@import '../custom';

.shepherd-footer {
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  padding: 0 $spacer $spacer;

  & .btn {
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

$cancel-icon-color: hsla(0, 0%, 50.2%, .75);

.shepherd-cancel-icon {
  background: transparent;
  border: 0;
  color: $cancel-icon-color;
  cursor: pointer;
  font-size: 2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color .5s ease;

  &:hover {
    color: $white;
  }
}

.shepherd-has-title {
  .shepherd-content {
    .shepherd-cancel-icon {
      color: $gray-400;

      &:hover {
        color: $white;
      }
    }

    .shepherd-header {
      background: $primary;
      padding: 0 $spacer;
    }
  }
}

.shepherd-title {
  color: $white;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  font-family: $headings-font-family;
  font-size: $h6-font-size;
  font-weight: 400;
  margin: $spacer 0;
}

.shepherd-header {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  padding: 0 $spacer;
}

.shepherd-text {
  font-size: $small-font-size;
  padding: $spacer;

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.shepherd-content {
  outline: none;
  padding: 0;
}

.shepherd-element {
  background: $white;
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity .3s, visibility .3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;

  &.shepherd-enabled {
    opacity: 1;
    visibility: visible;
  }

  &[data-popper-reference-hidden] {
    &:not(.shepherd-centered) {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  &,
  & *,
  & ::after,
  & ::before {
    box-sizing: border-box;
  }

  &[data-popper-placement^='top']>.shepherd-arrow {
    bottom: -8px;
  }

  &[data-popper-placement^='bottom']>.shepherd-arrow {
    top: -8px;
  }

  &[data-popper-placement^='left']>.shepherd-arrow {
    right: -8px;
  }

  &[data-popper-placement^='right']>.shepherd-arrow {
    left: -8px;
  }

  &.shepherd-centered>.shepherd-arrow {
    opacity: 0;
  }

  &.shepherd-has-title {
    &[data-popper-placement^='bottom'] {
      & > .shepherd-arrow {
        &::before {
          background-color: $primary;
        }
      }
    }
  }
}

.shepherd-arrow {
  &,
  &::before {
    height: 16px;
    position: absolute;
    width: 16px;
    z-index: -1;
  }

  &::before {
    background: $primary;
    content: '';
    transform: rotate(45deg);
  }
}

.shepherd-target-click-disabled {
  &.shepherd-enabled {
    &.shepherd-target {
      &,
      * {
        pointer-events: none;
      }
    }
  }
}

// sass-lint:disable no-transition-all
.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 9997;

  &.shepherd-modal-is-visible {
    height: 100vh;
    opacity: .5;
    transition: all .3s ease-out, height 0s 0s, opacity .3s 0s;

    path {
      pointer-events: all;
    }
  }
}
