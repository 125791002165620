@import '../custom';

.cssip-home-partners {
  &__logo {
    filter: grayscale(100%);
    height: 100px;
    opacity: .8;
    transition: filter .25s, opacity .25s;
    width: auto;

    &:hover {
      filter: grayscale(0%);
      opacity: 1;
    }
  }

  &__icon {
    transition: color .25s;
  }

  &__link {
    &:hover {
      .cssip-home-partners__icon {
        color: $secondary;
      }
    }
  }
}
