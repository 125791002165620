@import '../custom';

.cssip-table {
  &__vertical-word {
    line-height: 1em;
  }

  &__fixed-cell {
    background-color: $white;
    border: 0 !important; // Overriding bootstrap.
    left: 0;
    position: sticky;
    z-index: 1;

    &::before {
      border: 1px solid $table-border-color;
      bottom: 0;
      content: ' ';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--offset {
      left: 27px;
    }
  }
}
