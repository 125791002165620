@import '../custom';

.cssip-overview-property-box {
  background-color: $white;

  transition: background-color .25s;

  &--is-link {
    &:hover {
      background-color: scale-color($primary, $lightness: 90%);
      text-decoration: none;
    }
  }
}
