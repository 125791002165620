@import '../custom';

.cssip-real-time-values {
  &__number {
    @include media-breakpoint-up(md) {
      font-size: 4.5rem;
    }

    font-family: 'Oswald', sans-serif;
    font-size: 3rem;
    line-height: 1.2;
  }
}
