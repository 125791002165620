@import '../custom';

.cssip-home {
  &__card-box {
    background-color: $gray-100;
    transition: background-color .25s;

    &:hover {
      background-color: $white;
    }
  }
}
