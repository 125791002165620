@import '../custom';

.cssip-legend {
  &--horizontal {
    width: 100%;

    .cssip-legend {
      &__item {
        width: 20px;
      }

      &__item-dash {
        height: 4px;
        width: 1px;
      }

      &__gradient {
        height: 10px;
        margin: 0 10px;
      }
    }
  }

  &--vertical {
    max-height: calc(100vh - $navbar-height);

    .cssip-legend {
      &__item {
        height: 20px;
      }

      &__item-dash {
        height: 1px;
        width: 4px;
      }

      &__gradient {
        margin: 10px 0;
        width: 10px;
      }
    }
  }

}
