@import '../custom';

.cssip-layout {
  min-height: 100vh;

  &__primary {
    @include media-breakpoint-up(md) {
      max-height: calc(100vh - $navbar-height);
      position: sticky !important;
      top: $navbar-height;
      z-index: 1020;
    }

    max-height: 100vh;
  }

  &__nav {
    height: $navbar-height;
  }
}
